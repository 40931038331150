import React from 'react';

import '../styles/products.css'
import '../styles/global.css'
  
const Products = ({ setTitle }) => {
  setTitle("Products")
  return (
    <div id='products-content' className='content'>
      <h1>We are in stealth mode</h1>
      <h2>Products will be introduced in Q2/2024</h2>

      <div className="progress-bar">
        <div className="progress-bar-value"></div>
      </div>

    </div>
  );
};
  
export default Products;