import React from 'react';
import { Element, HeaderLogo, HeaderMenu, MenuLink } from './header';

const Header = () => {
  return (
    <Element>

      <HeaderLogo />

      <HeaderMenu>
        <MenuLink to='/'>HOME</MenuLink>
        <MenuLink to='/products'>PRODUCTS</MenuLink>
        <MenuLink to='/about'>ABOUT US</MenuLink>
        <MenuLink to='/contact'>CONTACT</MenuLink>
      </HeaderMenu>

    </Element>
  );
};

export default Header;