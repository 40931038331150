import React from 'react';
import { Element, FooterText } from './footer';

const Footer = () => {
  return (
    <Element>
      <FooterText>Airis Oy © {new Date().getFullYear()}</FooterText>
    </Element>
  );
};

export default Footer;