import React from 'react';

import '../styles/notfound.css'
import '../styles/global.css'
  
const NotFound = ({ setTitle }) => {
  setTitle("Page not found")
  return (
    <div id='notfound-content' className='content'>
      <h1>#404</h1>
      <h2>Page not found</h2>
      <a href="/">Return to home</a>
    </div>
  );
};
  
export default NotFound;