import React from 'react';

import '../styles/home.css'
import '../styles/global.css'
import '../styles/mobile.css'
  
const Home = ({ setTitle }) => {
  setTitle('Home')
  return (
    <div id='home-content' className='content'>
      <h1>AI And Machine vision</h1>
      <h2>Embedded solutions for your business</h2>
      <hr className='heading-devider'></hr>
      <a href="mailto:info@airis.fi"><span className='email-information-icon'><h3>info@airis.fi</h3></span></a>

    </div>
  );
};
  
export default Home;