import styled from 'styled-components';
  
export const Element = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 30px;
  text-align: center;
`;

export const FooterText = styled.div`
  display: inline-block;
  max-width: 200px;

  font-size: 14px;
  font-weight: 300;
  font-family: Roboto, Sans-Serif;
  letter-spacing: 0.7px;
  color: #FFFFFF;
  text-transform: uppercase;
  opacity: 0.7;
`;