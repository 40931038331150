import React from "react";
import { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import "./styles/global.css";

import Header from "./components/Header";
import Footer from "./components/Footer";

import Home from "./pages";
import Products from "./pages/products";
import About from "./pages/about";
import Contact from "./pages/contact";
import Coming from "./pages/coming";
import NotFound from "./pages/notfound";


const App = () => {
  const [title, setTitle] = useState('Home');

  useEffect(() => {
    // This will run when the page first loads and whenever the title changes
    document.title = "Airis Oy - " + title;
  }, [title]);

  const changeTitle = (event) => {
    // Function to change page title
    setTitle(event.target.value);
  };

  return (
    <Router>

      <Header />

      <Routes>
        <Route path='/' exact element={<Home setTitle={setTitle}/>} />
        <Route path='/products' element={<Products setTitle={setTitle}/>} />
        <Route path='/about' element={<Coming setTitle={setTitle}/>} />
        <Route path='/contact' element={<Contact setTitle={setTitle}/>} />
        <Route path='*' exact={true} element={<NotFound setTitle={setTitle} />} />
      </Routes>

      <Footer />

    </Router>
  );
}

export default App;
