import { NavLink as Link } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import Airis_logo from './airis_eye_logo.png';

export const Element = styled.div`
  width: 82%;
  margin: auto;
  height: 48px;
  @media only screen and (max-width: 798px) {
    width: 95%;
  }
`;

const blinkAnimation = keyframes`
  0% { height: 48px;opacity: 1;}
  1% { height: 1px; opacity: 0;}
  2% { height: 48px;opacity: 0.7;}
  4% { height: 1px; opacity: 0;}
  5% { height: 48px; opacity: 1;}
  10% { height: 45px; opacity: 0.95;}
  15% { height: 48px; opacity: 1;}
  20% { height: 45px; opacity: 0.9;}
  25% { height: 48px; opacity: 1;}
  30% { height: 45px; opacity: 0.95;}
  35% { height: 47px; opacity: 1;}
  36% { height: 10px; opacity: 0.2;}
  37% { height: 48px; opacity: 1;}
  42% { height: 45px; opacity: 0.95;}
  47% { height: 48px; opacity: 1;}
  52% { height: 45px; opacity: 1;}
  55% { height: 43px; opacity: 0.8;}
  60% { height: 46px; opacity: 1;}
  65% { height: 42px; opacity: 0.9;}
  70% { height: 48px; opacity: 1;}
  75% { height: 45px; opacity: 0.95;}
  80% { height: 48px; opacity: 1;}
  85% { height: 45px; opacity: 0.9;}
  90% { height: 43px; opacity: 1;}
  95% { height: 45px; opacity: 0.85;}
  100% { height: 48px; opacity: 1;}
`

export const HeaderLogo = styled.img.attrs({
    src: `${Airis_logo}`
})`
  position: fixed;
  width: 80px;
  max-height: 48px;
  transform: translateY(-50%);
  margin-top: 24px;
  
  animation-name: ${blinkAnimation};
  animation-duration: 15s;
  animation-delay: 1s;
  animation-iteration-count: infinite;

  @media only screen and (max-width: 520px) {
    width: 40px;
    max-height: 24px;
    animation: none;
  }
`;

export const HeaderMenu = styled.nav`
  display: inline-block;
  float: right;
  transform: translateY(-50%);
  margin-top: 24px;

  > * {
    &:last-child {
    border-right: 0px !important;
    }
  }
`;
  
export const MenuLink = styled(Link)`
  padding: 0px 30px 0px 30px;
  color: rgb(255, 255, 255, 0.7);
  text-decoration: none;
  font: normal normal medium 24px/32px;
  font-family: Roboto, Sans-Serif;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  border-right: 2px solid #BCD7D7;
  transition: 0.3s;

  @media only screen and (max-width: 798px) {
    padding: 0px 15px 0px 15px;
    font-size: 14px;
  }
  @media only screen and (max-width: 520px) {
    padding: 0px 7px 0px 7px;
    font-size: 12px;
  }
  &.active {
    color: rgb(255, 255, 255, 1);
    font-weight: 500;
  }
  &:hover {
    color: rgb(255, 255, 255, 1);
  }
`;

