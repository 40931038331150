import React from 'react';
import Airis_logo_full from '../styles/airis_logo.png';

import '../styles/contact.css'
import '../styles/global.css'
  
const Contact = ({ setTitle }) => {
  setTitle("Contact")
  return (
    <div id='contact-content' className='content'>
      <br/>
      <img src={Airis_logo_full} className="content-title-logo"/>
      <iframe className="contact-google-maps" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1972.0844268028127!2d25.022820816114912!3d60.37776518203789!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x468dff48fae39de9%3A0x978ed2386a50c4c8!2sAmerintie%201%2C%2004320%20Tuusula!5e0!3m2!1sfi!2sfi!4v1644674292230!5m2!1sfi!2sfi" width="100%" height="55%" loading="lazy"></iframe>
      <div id='contact-content-details'>
        <a href="mailto:info@airis.fi"><span className='email-information-icon'><h3>info@airis.fi</h3></span></a>
        <a><span className='address-information-icon'><h4>Amerintie 1, 04320</h4><h4>Tuusula FINLAND</h4></span></a>
        <h3>Business ID: 3227786-4</h3>
      </div>
    </div>
  );
};
  
export default Contact;