import React from 'react';

import '../styles/coming.css'
import '../styles/global.css'
  
const Coming = ({ setTitle }) => {
  setTitle("Coming soon...")
  return (
    <div id='coming-content' className='content'>
      <h1>About Us</h1>
      <h2>The curtains will be parted on 31.5.2024</h2>
    </div>
  );
};
  
export default Coming;